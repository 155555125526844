<template>
  <div>
    <TablePage>
      <template #toolbar>
        <el-button
          type="primary"
          @click="addRegion"
        >
          {{ $t("editServer.addRegion") }}
        </el-button>
      </template>
      <el-table
        border
        :data="regionList"
      >
        <el-table-column
          prop="number"
          :label="$t('editServer.regionNumber')"
          align="center"
        />
        <el-table-column
          prop="region_name"
          :label="$t('editServer.region_name')"
          align="center"
        />
        <el-table-column
          prop="start_ip"
          :label="$t('editServer.start_ip')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.start_ip ? scope.row.start_ip : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="end_ip"
          :label="$t('editServer.end_ip')"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.end_ip ? scope.row.end_ip : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              :disabled="[1,2,3,4,5].includes(scope.row.id)"
              @click="editRegion(scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="[1,2,3,4,5].includes(scope.row.id)"
              @click="sendRegion(scope.row.id)"
            >
              {{ $t("common.send") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              :disabled="[1,2,3,4,5].includes(scope.row.id)"
              @click="deleteRegion(scope.row.id)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </TablePage>
    <!-- 添加/修改区域信息 -->
    <el-dialog
      :title="$t('editServer.regionDialogTitle')"
      :visible.sync="regionDialog"
      width="800px"
      @closed="closeDialog"
    >
      <el-form
        ref="regionDialogForm"
        :model="regionMsg"
        label-width="150px"
        :inline="true"
        :rules="regionMsgrules"
      >
        <el-form-item
          prop="region_name"
          :label="$t('editServer.region_name')"
        >
          <el-input v-model="regionMsg.region_name" />
        </el-form-item>
        <el-form-item
          prop="start_ip"
          :label="$t('editServer.start_ip')"
        >
          <el-input v-model="regionMsg.start_ip" />
        </el-form-item>
        <el-form-item
          prop="end_ip"
          :label="$t('editServer.end_ip')"
        >
          <el-input v-model="regionMsg.end_ip" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="regionDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          v-if="isAdd"
          type="primary"
          @click="confirmAddRegion"
        >{{ $t("common.confirmAdd") }}</el-button>
        <el-button
          v-else
          type="primary"
          @click="confirmUpdateRegion"
        >{{ $t("common.confirmEdit") }}</el-button>
      </span>
    </el-dialog>
    <!-- 区域下发结果展示 -->
    <el-dialog
      :title="$t('editServer.groupSenddetail')"
      :visible.sync="sendResultDialog"
      width="900px"
    >
      <el-table
        :data="sendResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="msg"
          :label="$t('editServer.sendDetail')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
          width="100"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            >{{ releaseFilter(scope.row.success) }}</span>
            <span
              v-else
              style="color:#F56C6C"
            >{{ releaseFilter(scope.row.success) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="sendResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 区域删除结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="deleteResultDialog"
      width="900px"
    >
      <el-table
        :data="deleteResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="msg"
          :label="$t('editServer.deleteDetail')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.deleteResult')"
          width="100"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            >{{ releaseFilter(scope.row.success) }}</span>
            <span
              v-else
              style="color:#F56C6C"
            >{{ releaseFilter(scope.row.success) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="deleteResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_region_list,
  update_scheduling_regions,
  add_scheduling_regions,
  del_scheduling_regions,
  send_scheduling_regions
} from '@/api/server'
import TablePage from '@/components/TablePage.vue'

export default {
  components: {
    TablePage
  },
  data () {
    const validateIp = (rule, value, cb) => {
      const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
      if (reg.test(value)) {
        cb()
      } else {
        cb(new Error(this.$t('common.ipRemind')))
      }
    }
    return {
      regionList: [],
      regionDialog: false,
      isAdd: false,
      regionMsg: {},
      regionMsgrules: {
        region_name: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' }
        ],
        start_ip: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateIp, trigger: 'blur' }
        ],
        end_ip: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateIp, trigger: 'blur' }
        ]
      },
      sendResultDialog: false,
      deleteResultDialog: false,
      sendResult: [],
      deleteResult: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    releaseFilter (value) {
      if (value) {
        return this.$t('common.succ')
      } else {
        return this.$t('common.fail')
      }
    },
    async sendRegion (id) {
      const loading = this.$globalLoading()
      const res = await send_scheduling_regions({ scheduling_region_id: id })
      if (res.data.code === 0) {
        this.sendResult = res.data.data
        this.sendResultDialog = true
      }
      loading.close()
    },
    // 关闭窗口之后清除校验结果
    closeDialog () {
      this.$refs.regionDialogForm.clearValidate()
    },
    // 获取区域列表
    async getRegionList () {
      const res = await get_region_list()
      if (res.data.code === 0) {
        this.regionList = res.data.data
      }
    },
    // 确认添加区域
    confirmAddRegion () {
      this.$refs.regionDialogForm.validate(async (valid) => {
        if (valid) {
          const res = await add_scheduling_regions(this.regionMsg)
          if (res.data.code === 0) {
            this.$message.success(this.$t('common.addSuccess'))
            this.getRegionList()
            this.regionDialog = false
          }
        }
      })
    },
    // 确认修改区域
    confirmUpdateRegion () {
      this.regionMsg.region_id = this.regionMsg.id
      delete this.regionMsg.id
      delete this.regionMsg.number
      this.$refs.regionDialogForm.validate(async (valid) => {
        if (valid) {
          const loading = this.$globalLoading()
          const res = await update_scheduling_regions(this.regionMsg)
          if (res.data.code === 0) {
            this.$message.success(this.$t('common.updateSuccess'))
            this.getRegionList()
            this.regionDialog = false
          }
          loading.close()
        }
      })
    },
    // 添加区域
    addRegion () {
      this.regionMsg = {
        region_name: '',
        start_ip: '',
        end_ip: ''
      }
      this.isAdd = true
      this.regionDialog = true
    },
    // 修改区域
    editRegion (row) {
      this.regionMsg = JSON.parse(JSON.stringify(row))
      this.isAdd = false
      this.regionDialog = true
    },
    // 删除区域
    deleteRegion (id) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(async () => {
        const loading = this.$globalLoading()
        const res = await del_scheduling_regions({ region_id: id })
        if (res.data.code === 0) {
          this.deleteResultDialog = true
          this.deleteResult = res.data.data
          this.getRegionList()
        }
        loading.close()
      }).catch(() => {})
    },
    init () {
      this.getRegionList()
    }
  }
}
</script>

<style lang='scss' scoped></style>
